/* eslint-disable react/jsx-props-no-spreading,react/prop-types */
import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import asyncComponent from '../util/asyncComponent';
import {
  PROGRAM_MANAGEMENT_CLIENTS_ROUTE,
  PROGRAM_MANAGEMENT_PROGRAMS_ROUTE,
  PROGRAM_MANAGEMENT_ROUTE,
  PROGRAM_MANAGEMENT_PROJECTS_ROUTE,
} from './routes';

const RestrictedRoute = ({ component: Component, isSystemAdmin, authUser, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
    {
      return authUser ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/dashboard',
            state: { from: props.location },
          }}
        />
      )
    }
    }
  />
);

const RestrictedAdminRoute = ({ component: Component, isSystemAdmin, authUser, appsList, appCode, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
        {
            return authUser &&  (isSystemAdmin || appsList?.indexOf(appCode) > -1) ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/dashboard',
                        state: { from: props.location },
                    }}
                />
            )
        }
        }
    />
);

// eslint-disable-next-line react/prop-types
const Routes = ({ match, authUser, isSystemAdmin, appsList }) => (
  <Switch>
    <Route
      path={`${match.url}dashboard`}
      component={asyncComponent(() => import('./ResizableDashboard'))}
    />
    <RestrictedAdminRoute
      path={`${match.url}elis/instruments`}
      authUser={authUser}
      appsList={appsList}
      appCode={'ELIS'}
      isSystemAdmin={isSystemAdmin}
      exact
      component={asyncComponent(() => import('./LabInstrument'))}
    />
    <RestrictedAdminRoute
      path={`${match.url}elis/instruments/details/:assetId`}
      authUser={authUser}
      appsList={appsList}
      appCode={'ELIS'}
      isSystemAdmin={isSystemAdmin}
      exact
      component={asyncComponent(() => import('./LabInstrument/labInstrumentDetails'))}
    />
    <RestrictedAdminRoute
      path={`${match.url}elis/requests`}
      authUser={authUser}
      appsList={appsList}
      appCode={'ELIS'}
      isSystemAdmin={isSystemAdmin}
      exact
      component={asyncComponent(() => import('./Requests'))}
    />
    <RestrictedAdminRoute
      path={`${match.url}elis/requests/details/:requestId`}
      authUser={authUser}
      appsList={appsList}
      appCode={'ELIS'}
      isSystemAdmin={isSystemAdmin}
      exact
      component={asyncComponent(() => import('./Requests/requestDetails'))}
    />
    <RestrictedAdminRoute
      path={`${match.url}elis/dashboard`}
      authUser={authUser}
      appsList={appsList}
      appCode={'ELIS'}
      isSystemAdmin={isSystemAdmin}
      component={asyncComponent(() => import('./ResizableDashboard'))}
    />
    <RestrictedAdminRoute
        path={`${match.url}elis/location`}
        authUser={authUser}
        appsList={appsList}
        appCode={'ELIS'}
        isSystemAdmin={isSystemAdmin}
        component={asyncComponent(() => import('./SiteSelection'))}
    />
    <RestrictedAdminRoute
      path={`${match.url}elis/tableau-reports`}
      authUser={authUser}
      appsList={appsList}
      appCode={'ELIS'}
      isSystemAdmin={isSystemAdmin}
      component={asyncComponent(() => import('../modules/cbre-flow-tableau-reports/components/TableauReports'))}
    />
    <RestrictedAdminRoute
      path={`${match.url}elis/approvals`}
      authUser={authUser}
      appsList={appsList}
      appCode={'ELIS'}
      isSystemAdmin={isSystemAdmin}
      component={asyncComponent(() => import('modules/cbre-flow-approvals').then((module) => ({default: module.ApprovalDashboard})))}
    />
    <RestrictedAdminRoute
      path={`${match.url}elis`}
      authUser={authUser}
      appsList={appsList}
      appCode={'ELIS'}
      isSystemAdmin={isSystemAdmin}
      component={asyncComponent(() => import('../modules/cbre-flow-lab-dashboard/components/rootLabDashboard'))}
    />
      <RestrictedAdminRoute
          path={`${match.url}administration/user-management`}
          authUser={authUser}
          appsList={appsList}
          appCode={'ELIS'}
          isSystemAdmin={isSystemAdmin}
          component={asyncComponent(() => import('./Administration/UserRequests'))}
      />

    {/* <Route component={asyncComponent(() => import("app/routes/extraPages/routes/404"))}/> */}
  </Switch>
);

export default withRouter(Routes);
