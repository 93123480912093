/* eslint-disable */
import { SET_APPROVALS_COUNT } from './constants';

// Define the State Type
interface ApprovalsCount {
  myApprovalNeeded: number;
  externalApprovalNeeded: number;
  myApprovalHistory: number;
}

interface State {
  approvalsCount: ApprovalsCount;
}

// Define the Action Type
interface Action {
  type: string;
  payload: any;
}

const INITIAL_STATE: State = {
  approvalsCount: { myApprovalNeeded: 0, externalApprovalNeeded: 0, myApprovalHistory: 0 }
};

export default (state: State = INITIAL_STATE, action: Action): State => {
  switch (action.type) {
    case SET_APPROVALS_COUNT:
      return {
        ...state,
        approvalsCount: { ...state.approvalsCount,  ...action.payload}
      };
    default:
      return state;
  }
};